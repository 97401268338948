import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import SliceZone from '../components/sliceZone'
import Image from "../components/image"
import Fade from 'react-reveal/Fade'

const Case = ({ data: { prismicCase, allPrismicCase } }) => {

  let nextItem = [];
  allPrismicCase.edges.map((i, index) => {
    if (i.node.uid === prismicCase.uid) {
      nextItem[0] = allPrismicCase.edges[index+1];

      if (typeof nextItem[0] === 'undefined' ) {
        nextItem[0] = allPrismicCase.edges[0];
      }
    }
    return true;
  });

  return (
    <Layout>
        <SEO title={prismicCase.data.client.text} />
        <div className="c-case">
          <h1 className='c-case__client'>{prismicCase.data.client.text}</h1>
          <div className="c-case__description" dangerouslySetInnerHTML={{ __html: prismicCase.data.description.html }}></div>
          <div className="c-case__role">
            <h4>Mijn bijdrage:</h4>
            <ul>
              {prismicCase.data.role.map(i =>
                <li key={i.title.text}>
                  { i.title.text }
                </li>
              )}
            </ul>
          </div>
          <div className="c-case__section">
            <SliceZone allSlices={prismicCase.data.body} />
          </div>
          <div className={'o-case'} >
            <h4 className={'o-case__next'}>Volgend project:</h4>
            {nextItem.map((i) =>
              <Link to={ "/" + i.node.uid } className={'o-case__link'}>
                <Fade bottom distance={'100px'} duration={800} key={i.node.uid}>
                  <div>
                    <div className={'o-case__link__image'}>
                      <Image imagePath={ i.node.uid + "-teaser1.png" } imageAlt={ i.node.data.client.text } />
                    </div>
                    <div className={'o-case__link__card'}>
                      <div className={'o-case__link__description'}>
                        <h3>{ i.node.data.client.text }</h3>
                        <ul>
                          {i.node.data.role.map(data =>
                            <li key={data.title.text}>
                              { data.title.text }
                            </li>
                          )}
                        </ul>
                      </div>
                      <div className={'link link--arrow'}>Meer info</div>
                    </div>
                  </div>
                </Fade>
              </Link>
            )}
          </div>
          <div className={'c-case__return'}>
            <Link to={'/portfolio2020'} className={'link link--arrow link--inverted'}>Volledig overzicht</Link>
          </div>
        </div>
    </Layout>
  )
}

export default Case

export const pageQuery = graphql`
  query ($uid: String!) {

    prismicCase (uid: { eq: $uid }) {
      uid
      data {
        client {
          text
        }
        description {
          html
        }
        role {
          title {
            text
          }
        }
        body {
          ... on PrismicCaseBodySection {
            id
            slice_type
            items {
              filename {
                text
              }
            }
          }
          ... on PrismicCaseBodySubtitleDescription {
            id
            slice_type
          }
        }
        ordernumber
      }
    }

    allPrismicCase (sort: {fields: data___ordernumber}) {
      edges {
        node {
          uid
          data {
            client {
              text
            }
            role {
              title {
                text
              }
            }
          }
        }
      }
    }

  }
`
