import React from 'react'
import PropTypes from 'prop-types'

import Image from "../components/image"
import Fade from 'react-reveal/Fade'

const ImageList = ({ input }) => (
  <div className="c-case__imagelist">
    {input.items.map((i, index) =>
      <Fade bottom distance={'100px'} duration={800}>
        <Image imagePath={i.filename.text} imageAlt="Foto" key={input.id + index} />
      </Fade>
    )}
  </div>
)

export default ImageList

ImageList.propTypes = {
  input: PropTypes.object.isRequired,
}
