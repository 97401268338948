import React, { Component } from 'react'
import PropTypes from 'prop-types'

import ImageList from './../slices/imageList'

export default class SliceZone extends Component {
  render() {
    const { allSlices } = this.props
    const slice = allSlices.map(s => {
      switch (s.slice_type) {
        case 'section':
          return <ImageList key={s.id} input={s} />
        default:
          return null
      }
    })
    return <section>{slice}</section>
  }
}

SliceZone.propTypes = {
  allSlices: PropTypes.array.isRequired
}
